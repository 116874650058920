const messages = {
    swapShop: "Do you want to switch to<br> the new shop?",
    swapShopDespt: "The items selected from the previous shop will be saved and can be ordered later",
    sorryForInconvenient: 'We apologize for the inconvenience',
    openAt: 'Open at',
    shopIsClosedToday: 'Shop is Closed Today',
    checkOrderedItem: 'Check the ordered food items',
    tryAgainIn: 'Try agian at',
    lockUserPIN: "You have entered the wrong PIN more than 5 times",
    lockUserPINDespt: "Try again in 5 minutes",
    inValidPIN: 'Incorrect PIN',
    enterPasswordToAccessMenu: "Enter PIN to access the menu",
    customersCanGetPasswordFromStaff: "Customers can get the PIN from the staff",
    tableNotAvailable: "Table is not available",
    contactStaffToOpenTable: "Contact the staff to open the table",
    outOfRestaurantRange: "Not within the restaurant's range",
    phoneIsMoreThan100MetersAwayFromRestaurant: "Your phone is more than 100 meters away from the restaurant",
    restaurantIsCurrentlyClosed: "The restaurant is currently closed",
    restaurantOpenHours: "The kitchen is open from",
    packingSize: 'Package Size',
    shippingDelivery: 'Shipping',
    waitForPaymentDelivery: 'Pending Payment',
    readyForPcikup: 'Ready for Pick-Up',
    noNotifyNow: "No notifications at the moment",
    noNotifyNowDespt: "Once you start using the service, your notifications will appear here",
    newAddressDespt: "For easier delivery, please pin the correct destination and allow access to your location",
    deleteAddressSuccess: 'Delete Address Success',
    createProfileSuccess: "Create Profile Successfully",
    ex: 'Ex.',
    receiverName: 'Receiver Name',
    editAddress: 'Edit Address',
    nameInputInvalid: 'Incorrect Name',
    openInGoogleMap: 'Open Google Map',
    pleaseSelectPaymentMethod: 'Please Select Payment Method',
    noDeliveryAddress: 'No delivery address found',
    noDeliveryAddressDespt: 'Please add delivery address information',
    deliveryType: 'Delivery',
    pickupType: 'Pick-Up',
    shopIsClose: 'Shop is Closed',
    openingHoursDespt: 'Opening Hours',
    contactShop: 'Contact Shop',
    pickupAtShop: 'Pick-Up',
    thanksForOrderingDespt: 'Thank you for your kindness.<br>We hope to see you again!',
    deliveredSuccess: 'Delivered',
    expireIn: 'Expire In',
    waitForPayment: 'Pending Payment',
    waitForPaymentDespt: "Please wait for payment confirmation from the system.<br>If you have not yet made a payment, please complete the payment within the specified time to avoid order cancellation.",
    newOrderIn: 'New',
    inputRequestDetails: 'Specify request details',
    notification: 'Notification',
    noticeIfOrderReady: 'We will notify you when your order is ready for pickup',
    orderIsReady: 'Your order is now ready for pickup',
    thanksForOrdering: 'Thank you for using our service',
    toPay: 'To Pay',
    letOrder: 'Go to the menu page and start ordering now',
    noTransaction: 'No Transaction',
    menulists: 'Menulists',
    editPersonalDetails: 'Edit Details',
    trackOrder: 'Order Tracking',
    myAccount: 'My Account',
    cart: 'Cart',
    helpCenter: 'Help Center',
    allPurchaseHistory: 'Purchase History',
    purchaseHistory: 'Purchase History',
    personalInfo: 'Personal Details',
    cutlery: 'Cutlery',
    km: 'km.',
    totalAmountSum: 'Total',
    selectedItemList: 'Selected Items',
    iAm: 'I\'m',
    orderedBy: 'Ordered by',
    shareQRtoFriend: 'Share QR Code',
    share: 'Share',
    hasItemInBasket: 'There are items in the basket',
    hasItemInBasketDespt: 'Don\'t forget to send them to the kitchen',
    updatedAtQR: 'Updated at',
    orderSent: 'Order sent to the kitchen',
    orderSentDespt: 'The restaurant is preparing your food',
    checkGramdTotalIn: 'You can check the total amount at',
    vatNotInclude: 'This price does not include taxes, service charges, and discounts',
    accept: 'Accept',
    termOfService: 'Term of Service',
    onPending: 'Pending',
    OnSuccessOrder: 'Success',
    prepareingOrder: 'Preparing Order',
    deliveringOrder: 'Delivering Order',
    pickupOrderReady: 'Ready for Pick-up',
    pickedupOrder: 'Order is Picked-up',
    deliveredOrder: 'Order Delivered',
    shopReceivedOrder: 'Shop Received Order',
    orderId: 'Order ID',
    orderDetails: 'Order Details',
    deliveryFee: 'Delivery Fee',
    searchDriver: 'Searching for driver',
    driverFrom: 'Driver from',
    change: 'Change',
    deliveryAddress: 'Delivery Address',
    location: 'Location',
    addressName: 'Address Name',
    addressNameDespt: 'e.g., Condo Name, Village Name',
    addressDescription: 'Address Details',
    addressDescriptionDespt: 'e.g., Building Number, Alley',
    noteToDriver: 'Note to Driver',
    noteToDriverDespt: 'Ex. Place it on the table at the entrance',
    inputAddressForm: 'Enter Delivery Address',
    plsPinAddress: 'Pin your location',
    pinLocation: 'Pin Delivery Location',
    createNewAddress: 'Create New Address',
    savedAddress: 'Saved Address',
    phoneNumber: 'Phone Number',
    noRequired: 'Optional',
    resendCode: 'Resend code',
    didNotGetCode: 'Didn\'t get the code?',
    resendIn: 'Resend in',
    next: 'Next',
    createAccount: 'Create Account',
    createAccountDespt: 'Fill form below for create an account to continue',
    sendCodeViaSMS: 'Send code via SMS',
    inputOTP: 'Enter OTP',
    inputOTPDespt: 'Please enter the 6-digit code sent to',
    termAndPolicy: 'Terms and Policy',
    termAndPolicy1: 'By using our service, you agree to',
    termAndPolicy2: 'Click to read the full Terms and Policy agreement',

    loginWithPhoneNumber: 'Login with Phone Number',
    loginWithPhoneNumberDespt: 'Enter your phone number to receive an OTP for identity verification',
    backToOrder: 'Back to Menu',
    PaymentMethod: 'Payment',
    paymentAmount: 'Payment amount',
    paymentConfirmation: 'Payment Confirmation',
    cancelPayment: 'Cancel Payment',
    paidSuccess: 'Payment Completed',
    haveNotPurchased: 'Have not purchased yet',
    haveNotPurchased2: 'If Payment Fails, Please Contact Staff',
    canNotOrderItem: 'You cannot order food while making payment',
    checkPaymentStatus: 'Check Status',
    pendingPayment: 'Payment Pending',
    askForReceipt: 'If you need a receipt, please contact the staff',
    paymentSuccess: 'Payment Successful',
    summaryPayment: 'Summary',
    numberOfGuest: 'Number of Guest',
    closeTab: 'Close',
    addCredit: 'Add Credit Card',
    QRPayment: 'QR Payment',
    cardPayment: 'Credit Card',
    payWithStaff: 'Pay At Counter',
    selectPaymentMethod: 'Select Payment Method',
    checkOut: 'Check Out',
    startOrderBeforeCallStaff: 'Please order your food before calling the staff',
    basket: 'Basket',
    spoon: 'Request for cutlery',
    knife: 'Request for knife',
    chopstick: 'Request for chopstick',
    cup: 'Request for cup',
    plate: 'Request for plate',
    glass: 'Request for glass',
    payBill: 'Request for payment',
    viewAll: 'View All',
    recommendMenu: 'Recommend',
    OnSuccess: 'Success',
    OnPreparing: 'Preparing',
    messageSent: 'Please wait for our staff',
    outOfStock: 'Out of Stock',
    QRCode: 'QR Code',
    GenerateQRCode: 'Generate QR Code',
    ForQROrder: 'For ordering food and asking for in-store service',
    QROrderDescription: 'You can generate a QR code for customers to scan for ordering food and asking for in-store service',
    SelectTable: 'Choose a table and input number of guests.',
    shopname: 'Shop Name',
    numberOfCustomer: 'Number of customers (people)',
    numberOfCustomerDescription: 'Input the number of customers, minimum 1 person',
    currentUser: 'User',
    guest: 'Guests',
    orderItem: 'Order Summary',
    orderItemNav: 'Order Summary',
    callStaff: 'Call Staff',
    callStaffDialog: 'What kind of assistance do you need?',
    cutlery: 'Request for cutlery',
    seasoning: 'Request for seasoning',
    refillDrink: 'Request for drink refill',
    other: 'Other',
    tableNumber: 'Table',
    menuList: 'Menu',
    selectedItem: 'Order Food',
    selectedItemNav: 'Order Food',
    checkOrderBeforeConfirm: 'Please verify your order details and total before sending it to the restaurant',
    checkOrderBeforeConfirm2: '',
    startOrder: 'Start Order',
    foodCategory: 'Category',
    searchByName: 'Search Product By Name...',
    noPreduct: 'No Product available',
    noSelectedProduct: 'No selected item',
    noSelectedProductDespt: 'If you have selected products, the list of your selections will be displayed on this page',
    backToMenu: 'Go to Menu',
    removeSelected: 'Do you want to remove this item?',
    confirmOrder: 'Submit Order',
    endOfTransection: 'End of transaction',
    endOfTransectionDialog: 'In case of usage issues, please contact the staff',
    note: 'Note',
    orderTime: 'Order time',
    orderMoreFood: 'Return to Main Menu',
    orderPreparing: 'Our chefs are preparing your meal. Please wait a moment. Your food will be served shortly',
    orderPreparing2: '',
    sendingOrder: 'Sending Your Order to The Kitchen...',
    login: 'Login',
    forgetPassword: 'Forget Password',
    loginWithGmail: 'Login With Gmail',
    or: 'or',
    loginAsStaff: 'Login As Staff',
    billOrder: 'Bill Order',
    table: 'Table',
    billOrderDialog: 'Scan the QR code to view the menu and place an order',
    billOrderDialog2: 'In case of usage issues, please contact the staff',
    selectTableAleart: 'Please select a table before generating the QR code',
    emptyCartDialog: 'No Transaction',
    emptyCartDialogDespt: 'If you confirm the order, the list of your ordered items will be displayed on this page',
    served: 'Served',
    contactOurStaff: 'Contact Our Staff',
    contactOurStaff2: 'In case asking for help',
    addToCart: 'Add to Cart',
    noteToShop: 'Note to shop',
    addYourRequest: 'Add your request',
    branchName: 'Branch Name',
    businessType: 'Business Type',
    taxID: 'Tax ID',
    vat: 'Tax',
    exclusive: 'Exclusive',
    inclusive: 'Inclusive',
    serviceCharge: 'Service Charge',
    address: 'Address',
    address1: 'Address Line 1',
    address2: 'Address Line 2',
    tel: 'Tel',
    businessType1: 'General',
    businessType2: 'Restaurant',
    businessType3: 'Hostel',
    shopopen: 'Open - Closed',
    open: 'Open',
    closed: 'Closed',
    languagetxt: 'Language',
    language: 'Language & Timezone',
    languageTH: 'Thai',
    languageEN: 'English',
    setting: 'Settings',
    shop: 'Shop',
    timezone: 'Timezone',
    deliveryProviders: 'Delivery Providers',
    adddeliveryProviders: 'Add Providers',
    lastUpdate: 'Last update',
    logo: 'Logo',
    updateData: 'Update data',
    submit: 'Submit',
    cancel: 'Cancel',
    normal: 'Normal',
    loading: 'loading...',
    editing: 'Editing',
    confirmUpdate: 'Do you want to updated data?',
    selectedShoptype: 'Selected Business Type...',
    selectshop: 'Select Branch Name',
    save: 'Save',
    reporttxt: 'Reports',
    general: 'General',
    signOut: 'Sign Out',
    registration: '',
    unit: 'Unit',
    category: 'Category',
    product: 'Product',
    export: 'Export',
    exportKBank: 'Export K-Bank',
    date: 'Date',
    to: 'To',
    status: 'Status',
    vatAmount: 'Tax',
    netTotalNonVAT: 'Total (non-Tax)',
    receiptNumber: 'Receipt Number',
    grandTotal: 'Grand Total',
    netAmountVATTotal: 'Sub-Total',
    netTotalIncVAT: 'Total (incl-Tax)',
    proofOfPayment: 'Proof of payment',
    paymentType: 'Payment Type',
    paymentType0: 'Cash',
    paymentType1: 'Credit card',
    paymentType2: 'Coupon',
    paymentType3: 'Quickpay',
    paymentType4: 'Promptpay',
    paymentType5: 'Alipay',
    paymentType6: 'Deposit',
    paymentType7: 'Delivery',
    paymentType8: 'Online',
    paymentType9: 'Line Pay',
    paymentType10: 'Custom Pay',
    paymentType12: 'KBank',
    paid: 'Payment value',
    sentSuccessfully: 'Sent successfully',
    pluCode: 'Barcode',
    netAmount: 'Net Total',
    discount: 'Discount',
    timeOfOrder: 'Time of order',
    orderDate: 'Order Date',
    preparing: 'Preparing',
    paymentDetail: 'Payment Detail',
    orderNumber: 'Order Number',
    delivered: 'Due date',
    deliveredDate: 'Lead time',
    payment: 'Payment',
    productName: 'Product Name',
    quantity: 'Quantity',
    priceUnit: ' Price / Unit',
    baht: 'Baht',
    profit: 'Profit',
    sales: 'Sales',
    billAmount: 'Bill total',
    total: 'Total',
    bill: 'Bill',
    discountAmount: 'Discount Amount',
    averageBill: 'Average / bill',
    voidbillTotal: 'Voidbill Total',
    salesByDaily: 'Sales By Daily',
    salesByMonth: 'Sales By Month',
    bestSellers: 'Best sellers',
    bestSellerstype: 'Best sellers category',
    salesBydate: 'Sales by date',
    number: 'No.',
    categoryName: 'Category Name',
    newOrder: 'Sent Order',
    reject: 'Reject',
    inProgressTime: 'In Progress time',
    rejectTime: 'Reject Time',
    cancelTime: 'Cancel Time',
    cause: 'Cause',
    preparingToShip: 'Preparing',
    totalAmount: 'Total',
    order: 'Order',
    deposit: 'Deposit',
    billSave: 'Save',
    ReceivingOrder: 'Open table/Receiving order',
    orderNo: 'Order No.',
    remark: 'Remark',
    receiptStatus: 'Receipt Status',
    back: 'Back',
    detail: 'Detail',
    countSales: 'Quantity',
    onhandQty: 'Balance',
    stdCost: 'Cost',
    employees: 'Employees',
    employeesname: 'Employees Name',
    salesTotal: 'Sales Total',
    totalBill: 'All bill count',
    billActive: 'Selling bills count',
    billVoice: 'Cancel bills count',
    billDeposit: 'Deposit bills count',
    discountItem: 'Total item discount',
    billDiscountAmount: 'Total bill discount',
    billDiscount: 'Billing discount count',
    payTotal: 'Payment Total',
    deliveryProvider: 'Delivery Provider',
    deliveryDetail: 'Delivery Detail',
    customDetail: 'Custom payment Detail',
    customPaymentName: 'Custom Payment Name',
    firstInvoiceNO: 'First bill no.',
    lastInvoiceNO: 'Last bill no.',
    roundNo: 'Round No.',
    closeAt: 'Close At',
    closeBy: 'Close By',
    totalCashSales: 'Total Cash Sales',
    initialChange: 'Initial Change',
    totalCashIn: 'Total cash in',
    totalCashOut: 'Total cash out',
    actualInDrawer: 'Actual in drawer',
    expectedInDrawer: 'Expected in drawer',
    difference: 'Difference',
    voidBillDetail: 'Void bill detail',
    cancelBy: 'Cancel By.',
    cancelDate: 'Cancel Date',
    searchByEmployees: 'Search by employees',
    search: 'Search',
    timeIn: 'Clock in',
    timeOut: 'Clock out',
    time: 'Time',
    hours: 'Hours',
    seconds: 'Seconds',
    netTotal: 'Total',
    reason: 'Reason',
    searchByProduct: 'Search by product',
    sortBy: 'Sort By',
    last: 'Lasted',
    price: 'Product price',
    sortByNumMin: 'Min price',
    sortByNumMax: 'Max price',
    documentNo: 'Document No.',
    transactionType: 'Transaction Type',
    updatedBy: 'Updated By',
    transactionType1: 'Sell',
    transactionType2: 'Refund',
    transactionType3: 'Stock in',
    transactionType4: 'Stock out',
    transactionType5: 'Adjust stock in',
    transactionType6: 'Adjust stock out',
    transactionType7: 'Redeem point',
    transactionType8: 'Delete stock in',
    transactionType9: 'Delete stock out',
    transactionType10: 'Edit stock in',
    transactionType11: 'Edit stock out',
    billNo: 'Bill No.',
    docTotal: 'Total',
    customer: 'Customer',
    username: 'Username',
    dateOfBill: 'Date Of Bill',
    selectDate: 'Select Date',
    dialogSelectCategory: 'Please select category for search',
    dialogUpdateDocumentSuccess: 'Update document successfully',
    dialogfound: 'The information was entered incorrectly.',
    isVatIncluded: 'Is VAT Included',
    vatIccludes: 'VAT',
    confirmStock: 'Confirm stock',
    confirm: 'Confirm',
    deleteDoc: 'Delete Document',
    confirmUpdateDocModal: 'Would you like to update stock document?',
    confirmSaveDoc: 'Confirm saving documents',
    confirmDeleteDoc: 'Confirm deleted documents',
    confirmDeleteModal: 'Would you like to deleted stock document?',
    dialogSaveDocumentSuccess: 'Save document successfully',
    createStockIn: 'Create Stock-In',
    confirmSaveDocModal: 'Would you like to save stock document?',
    selectShopPlease: 'Select shop please',
    selectProductPlease: 'Select product please',
    duplicateProduct: 'You have selected this item.',
    productInvilid:
        'The item you selected is invalid. Please contact the system administrator.',
    confirmStockOut: 'Confirm stock out',
    dialogSaveDocumentOutSuccess: 'Save document successfully',
    confirmSaveDocStockOutModal: 'Would you like to save stock out document?',
    adjustDocType: 'AdjustDoc Type',
    adjustDocType1: 'Improve',
    adjustDocType2: 'Reduce',
    beforeAdjust: 'Before Adjust stock',
    afterAdjust: 'After Adjust stock',
    adjust: 'Adjust stock',
    createDoucument: 'Create Document',
    transferType: 'Transfer Type',
    transferType1: 'Transfer in',
    transferType2: 'Transfer out',
    new: 'New',
    finish: 'Finish',
    refDocument: 'Document Reference',
    statusDoc: 'Document Status',
    dialogSaveTransferDocumentSuccess: 'Create transfer doucument successfully',
    destinationShop: 'Destination shop',
    destinationShopBranch: 'Destination branch',
    sourceBranch: 'Source Branch',
    sourceShop: 'Source Shop',
    sourceAddress: 'Source address',
    destinationAddress: 'Destination address',
    saveTransfer: 'Save transfer stock',
    confirmSaveTransfer: 'Would you like to save transfers stock?',
    savinserviceChargegDoc: 'Saving document',
    selectdestinationPlease: 'Please select destination branch ',
    validatedestinationproduct: "In destination branch don't have product",
    validateProduct: 'Please select product',
    created_by: 'By.',
    receive: 'Receive',
    updateDoc: 'Update document',
    cancelDoc: 'Cancel document',
    confirmCancelDoc: 'Do you want to cancel this document?',
    cancelingDoc: 'Canceling documents',
    updatingDoc: 'Updatting documents',
    updateDocFail: "Can't updated documents",
    cancelDocSuccess: 'Cancel documents',
    cancelDocFail: "Can't cancel documents",
    refDocumentTransfer: 'Ref. Document',
    comfrimTransferIn: 'Confirm stansfer stock in',
    dilogcomfrimTransferIn: 'Do you want to transfer stock in document?',
    transferInDetail: 'Transfer stock detail',
    totalNet: 'Net total',
    confirmAdjust: 'Confirm adjust stock',
    createStockOut: 'Create stock out',
    favorite: 'Favorite',
    isOnScreen: 'Is On Screen',
    negotiatePrice: 'Negotiate Price',
    productTypeP: 'General Product',
    productTypeBOM: 'Bill of Meterial',
    productTypeSN: 'Serial Product',
    productTypeSV: 'Service (non-stock)',
    productPLU: 'Other packaging sizes',
    productBOM: 'Meterial',
    productSN: 'Serial Number',
    morePrice: 'Other Prices',
    cost: 'Cost',
    quantityPrice: 'Quantity',
    exceedUc: 'Price',
    changeImg: 'Image change',
    addProduct: 'Add Product',
    selectImg: 'Select image',
    deleteImg: 'Delete image',
    selectImgFromPC: 'Select image from PC',
    confirmSaveImgDialog: 'Do you want to save product?',
    confirmSaveImg: 'Confirm to save product.',
    addPrice: 'Add Price',
    editPrice: 'Edit Price',
    dialigDeleteProduct: 'Do you want to deleted product?',
    confirmDeleteProduct: 'Confirm to delete this product.',
    productType: 'Product Type',
    countProduct: 'Count of product',
    addUnit: 'Add Unit',
    editUnit: 'Edit Unit',
    active: 'Active',
    inactive: 'In Active',
    confirmSaveUnitDialog: 'Do you want to save unit?',
    confirmSaveUnit: 'Confirm to save this unit.',
    dialigDeleteUnit: 'Do you want to deteted unit?',
    confirmDeletUnit: 'Confirm to delete this unit.',
    confirmSaveCategoryDialog: 'Do you want to save category?',
    confirmSaveCategory: 'Confirm to save this category.',
    dialigDeleteCategory: 'Do you want to deteted category?',
    confirmDeletCategory: 'Confirm to delete category.',
    addCategory: 'Add Category',
    editCategory: 'Edit Category',
    bgColor: 'Background color',
    selectbgColor: 'Select background color',
    validateCategoryname: 'Please enter a category name.',
    sequence: 'Sequence',
    enterProduct: 'Please enter product name.',
    enterPrice: 'Please enter product price.',
    selectCategory: 'Please enter a product category.',
    selectUnit: 'Please enter a product unit.',
    confirmSaveProduct: 'Do you want to save this product?',
    confirmSaveProductPrice: 'Do you want to save other price?',
    validatepackQty: 'Please enter a Price/Unit.',
    validatepackUc: 'Please enter a price.',
    validatePRU: 'Duplicate data, Please enter again.',
    createProductSuccess: 'Created product is success',
    addSerial: 'Add Serial',
    add: 'Add',
    createSerial: 'Generate Serial number',
    available: 'Ready to sales',
    soldOut: 'Sold Out',
    deleteSuccess: 'Deleted is success. ',
    enterSerial: 'Please enter Serial Number',
    confirmSaveSerial: 'Do you want to save this Serial Number?',
    confirmDelete: 'Do you want to delete this Serial Number?',
    addProductPLU: 'Add packaging sizes',
    img: 'Image',
    SKURatio: 'Ratio',
    editProductPLU: 'Edit packaging sizes',
    enterSKURatio: 'Please enter Ratio',
    comfrimDeletePLU: 'Do you want to delete this packaging sizes?',
    useStepPrice: 'Use step price',
    addBOM: 'Add Material',
    confirmSaveBOM: 'Do you want to save Material?',
    user: 'User',
    userDetail: 'User Detail',
    planDetail: 'Plan Detil',
    currentPlan: 'Current Plan',
    expireDate: 'Expire Date',
    startDate: 'Start Date',
    dialogSignOut: 'Do you want to sign out?',
    confirmSignOut: 'Confirm for sign out',
    countMember: 'All members', //
    addMenber: 'Add member',
    searchMember: 'Search by name email and Tel',
    dateOfMember: 'Date of member',
    recentVisit: 'Recent visit',
    memberName: 'Member name',
    email: 'Email',
    paymentTotal: 'Payment total',
    point: 'Point',
    pointtxt: 'Point',
    postalCode: 'Postal code',
    saveing: 'Saving . . .',
    edit: 'Edit',
    editMember: 'Edit member',
    deleteing: 'Deleting . . .',
    dialogDeleteMember: 'Do you want to deleted member ',
    confirmDeleteMember: 'Confirm for delete',
    valtdateMember: 'Please fill information ',
    createMemberSuccess: 'Add member is successfully !!',
    updateMemberSuccess: 'Edit member is successfully !!',
    deleteMemberSuccess: 'Delete member is successfully !!',
    onhandQtyMin: 'Onhand Min',
    onhandQtyMax: 'Onhand Max',
    onhandQtySort: 'OnhandQty',
    showProductAll: 'All Product type',
    updateAt: 'Last update',
    sortName: 'Name',
    stockUnit: 'Cost/Unit',
    deleteCategory: 'Deleted category',
    selectd: 'Select',
    searchProduct: 'Search Product',
    reset: 'Reset',
    selectdProduct: 'Select product',
    information: 'Information',
    adjustStockList: 'Adjust stock product',
    adjustDetail: 'Add product to adjust stock list add setting product',
    transferoutList: 'Transfer stock product',
    addProductTransfer:
        'Add product to transfer stock list add setting product',
    transferDetail: 'Product to Stock out list add setting product',
    transferinList: 'Product to Stock in list add setting product',
    stockOutList: 'Stock out product',
    addstockOutList: 'Add product to Stock out list add setting product',
    stockinList: 'Stock in product',
    addProductStockin: 'Add product to Stock in list add setting product',
    checkCount: 'Check stock',
    createAdjustStock: 'Create adjust stock',
    memberDetail: 'Member infomation',
    package: 'Package detail',
    startPackate: 'Start Package',
    endPackate: 'End Package',
    name: 'Name',
    productImg: 'Product image',
    fistname: 'Fristname',
    lastname: 'Lastname',
    saveMember: 'Save infomation',
    confirmSaveMember: 'Do yo want save member ',
    yesOrNo: 'yes or no',
    totaValue: 'Total',
    deleteMember: 'Delete member',
    frequently: 'Frequently product',
    recentPurchase: 'Last purchase',
    paymentDescription: 'Payment description',
    validateInfomation: 'Please input information',
    validateTel: 'Phone number is incorrect',
    validateOTP: 'OTP is incorrect',
    validateEmail: 'Email is incorrect',
    saveSuccess: 'Save Successfully',
    txtcustomer: 'Customer',
    customerName: 'Customer name',
    customerCount: 'Count of customer',
    addCustomer: 'Add customer',
    permissionManage: 'Permission',
    mainMenu: 'Main Menu',
    page: 'Page',
    show: 'Show',
    delete: 'Delete',
    administratorTools: 'Administrator tools',
    deviceName: 'Device Name', //ชื่ออุปกรณ์
    lastSyncProduct: 'Last Sync', //เวลาใช้งานล่าสุด
    version: 'Version',
    posstatus: 'Status', //สถานะการเชื่อมต่อกับระบบ
    posNumber: 'POS Number',
    titleSilomconnect: 'Never miss any sales with services from',
    updateSilom: 'Update daily sales via LINE for free',
    addfriend: 'Add friend',
    enterToReciveService: 'Press to receive a code for using the service.',
    verifyCodeToUsed:
        'To start using the system. You can press to receive a code to verify your identity.',
    verifyfivemin: 'The verification code can be used within 5 minutes.',
    yourVertify: 'Your verify code is', //รหัสของคุณคือ
    pressCode: 'Press receive code', //กดรับรหัส
    exCode: 'The code expires within', //รหัสหมดอายุภายใน
    sessionEx: 'Login session expired. Please login again.', // เซสชันการเข้าสู่ระบบหมดอายุ กรุณาล็อกอินใหม่อีกครั้ง
    refresh: 'Refresh',
    selectedFile: 'Selected File',
    fileSize: 'File size no more than 2 MB.',
    isBeingToUsed: 'Is being used',
    notInUse: 'Not in use',
    delete: 'Delete',
    confirmUpdateInformation: 'Confirm to update information',
    memberCount: 'Count',
    orderMachine: 'Order Device',
    textMember: 'Member',
    manageShop: 'Shop information management',
    lansetting: 'Shop setting language and timezone',
    timezoneSetiing: 'Setting time zone for shop',
    deliveryProviderSetting: 'Delivery Providers for shop ',
    toolsSetting: 'Manage POS machines for shop',
    checkStockPLU: 'Product balace < 0',
    searching: 'Searching',
    allProduct: 'All Product',
    foundProductOnhand: 'Found product have stock onhand < 0 ',
    confirmAdjustBefor: 'Do you want to adjust by system automatic?',
    requireAdjust: 'Yes',
    imrequireAdjust: 'No',
    islimitItemTodoc: 'You can select up to 200 items / 1 document.',
    barcodePrint: 'Barcode Print',
    printEx: 'Print & Example',
    print: 'Print',
    downloadAndExport: 'Download and print',
    selectFormat: 'Select paper format',
    peperFormat: 'Paper format',
    optional: 'Optional',
    standardA427: 'Standard A4 : 27 items / page',
    standardA450: 'Standard A4 : 52 items / page',
    standardA460: 'Standard A4 : 60 items / page',
    standardA556: 'Standard A4 : 56 items / page (sticker size:A5)',
    standardA550: 'Paper A5 : 52 items / page',
    standardA560: 'Paper A5 : 60 items / page',
    countBarcode: 'Count barcode',
    barcode: 'Barcode',
    setFontsize: 'Font setting',
    accessPermissionDes: 'Assign access for users.',
    deleteUser: 'Confirm to delete user',
    confirmDeleteUser: 'Would you like to deleted user ?',
    passAtless: 'Password should be at least 6 characters.',
    inValidPass: 'Incorrect password',
    createBy: 'Created By',
    adjustAutoDes:
        "If you don't want the system to update automatically May cause the total balance of the product to be wrong.",
    addUser: 'Add user',
    isMenu: 'Is on menu for customer',
    menuSmart: 'Set up product menu',
    smartSettingTitle: 'Menu Setting',
    smartSetting: 'Setting menu for Silom CRM ',
    confirmEnable: 'Do you want to enable menu ?',
    confirmDisEnable: 'Do you want to disable menu ?',
    confirmSelfOrderEnable: 'Do you want to enable self-order menu ?',
    confirmSelfOrderDisable: 'Do you want to disable self-order menu ?',
    enable: 'Enable',
    disable: 'Disable',
    usabilityStatus: 'Enabled',
    manageSmartOnProduct:
        'You caadd/edit" the list of products you want to show on menu for customer at the page.',
    manageSmartOnCategory:
        'You can "add/edit" the list of category you want to show on menu for customer at the page.',
    turnonSmart: 'and enable menu',
    manageShopDes: 'You can refer to the user manual in -',
    desSmartMenu:
        'Set up product menu for displaying the E-menu  on Line for easier customer access and can order products by themself.',
    link: 'link',
    createTranferOut: 'Create tranfer out document',
    roundingValue: 'Rounding Adj.',
    limitItems: 'Can select product to limit 200 items',
    IncomeCategory: 'Income ',
    ExpenseCategory: 'Expense ',
    saveBy: 'Save by',
    saveDate: 'Save date',
    drawerCate: 'Categories',
    openingHours: 'Opening hours',
    shopLocation: 'Shop location',
    sunday: 'Sunday',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    lat: 'Latitude',
    lng: 'Longitude',
    shopCoverSetting: 'Shop cover setting',
    shopCoverSettingDes: 'Setting cover shop for displays on screen customer',
    locationSettingDes: 'Location setting ',
    updatelocation: 'Update location', //Update location
    searchLocation: 'Search Location ', //Search Location
    imaginaryLine: 'Imaginary Line', //Imaginary Line
    openingHoursDes: 'Setting opening time for shop',
    paymentDescriptionConfig: 'Select Payment Method',
    creditCard: 'Credit Card',
    promptPay: 'Promptpay',
    coupon: 'Coupon',
    quickpay: ' Krungsri Quick Pay',
    alipay: 'Alipay',
    linepay: 'Rabbit LINE Pay',
    thaidotcom: 'Thai Dot Com Payment',
    mounth: 'mounth',
    dayMY: 'Date',
    item: 'Items',
    byStockIn: 'Stock-in',
    stockOutOther: 'Other',
    drawerSales: 'Sales',
    sumIncomeExpenses: 'Summary of income-expenses for ', //Summary of income-expenses for the month
    incomeOther: 'Income Other',
    loss: 'Loss',
    grossProfit: 'Gross Profit',
    newUser: 'New user',
    userType: 'User Type',
    password: 'Password',
    confirmPassword: 'Confirm Password',
    ParticipatingProducts: 'Select products',
    descriptionSilomOrder: 'Select products for order.silompos.com',
    cashierManagement: 'Cashier Management',
    cashierManageButton: 'Manage',
    cashierUsernamePassword: 'Create Username/Password',
    cashierCRUDDescription: 'For login order.silompos.com',
    password: 'Password',
    placeholderPassword: '6 characters minimum',
    resetPassword: 'Reset password',
    oldPassword: 'Old password',
    oldPasswordPlaceholder: 'Old password',
    newPassword: 'New password',
    purchaseDetail: 'Purchase Details',
    uploadImage: 'Upload Image',
    keyword: 'keyword',
    firstname: 'First Name',
    lastname: 'Last Name',
    province: 'Province',
    district: 'District',
    subdistrict: 'Sub District',
    addressNumber: 'Address',
    setAsDefaultAddress: 'Set as Default address',
    setAsTaxAddress: 'Set as Tax address',
    setAsShippingAddress: 'Set as Shipping address',
    contact: 'Contact',
    setAsDefault: 'Set as default',
    addressLists: 'Address lists',
    default: 'Default',
    taxAddress: 'Tax invoice',
    shippingAddress: 'Shipping',
    addAddress: 'Add',
    createdAt: 'Created',
    updatedAt: 'Updated',
    by: 'by',
    readyForSale: 'ready for sale',
    sold: 'sold',
    delete: 'delete',
    items: 'Items',
    itemDiscount: 'Item discount',
    failedToSave: 'Failed to save!',
    dateOfBirth: 'Date of birth',
    menu: 'Menu',
    dashboardMenu: 'Dashboard',
    transactionMenu: 'Transaction',
    currentBillMenu: 'Current Bill',
    reportMenu: 'Report',
    orderDeviceMenu: 'Order Device',
    memberMenu: 'Member',
    inventoryMenu: 'Inventory',
    productMenu: 'Product',
    selfOrderMenu: 'Sales Channels',
    settingMenu: 'Setting',
    salesReportByDate: 'Sales by Date',
    salesReportByBill: 'Sales by Bill Detail',
    salesQuantitySummaryByProductReport: 'Sales by Product',
    quantitySummaryByPLUReport: 'Sales by Product (PLU)',
    nonSellingProduct: 'Non Selling Products',
    salesSummaryByCashierReport: 'Sales by Cashiers',
    salesSummaryByCategoryReport: 'Sales by Category',
    paymentReport: 'Payment',
    salesTaxReport: 'Sales Tax',
    drawerReport: 'Drawer',
    voidBillReport: 'Void bill',
    timesheetReport: 'Timesheet',
    stockInByListReport: 'Stock in by Product',
    stockOutByListReport: 'Stock out by Product',
    nonAdjustStockProductReport: 'Non adjust stock product',
    exportProductReport: 'Export product',
    salesSummaryByOrderReport: 'By Order Device',
    salesSummaryProductByOrderReport: 'By Product',
    voidBillByOrderReport: 'Void bill',
    inventoryReport: 'Inventory Report',
    inventoryReportByPLU: 'Inventory Report by PLU',
    inventoryReportBySerial: 'Inventory Report by Serial No.',
    stockMovement: 'Stock Movement',
    stockInDocument: 'Stock-In document',
    stockOutDocument: 'Stock-Out document',
    adjustStockDocument: 'Adjust Stock document',
    selfOrder: 'Self Order',
    cashier: 'Cashier',
    permission: 'Permission',
    menuSetting: 'Menu Setting',
    management: {
        txtManagement: 'Management',
        user: 'Account',
        shopBranch: 'Shop and Branch',
        productManagement: 'Product',
        unitManagement: 'Unit',
        categorytManagement: 'Category',
        inventory: 'Inventory',
        stockIn: 'Stock-In',
        stockOut: 'Stock-Out',
        adjustStock: 'Adjust Stock',
        checkStock: 'Check stock',
        stockMovement: 'Stock Movement',
        transferStock: 'Transfer Stock',
        reportByPLU: 'Inventory Report by PLU',
        reportBySKU: 'Inventory Report',
        txtreportBySKU: 'Inventory Report',
        txtreportByPLU: 'Inventory Report by PLU',
        stockInDoc: 'Stock-In document',
        stockOutDoc: 'Stock-Out document',
        adjustStockDoc: 'Adjust Stock document',
        transferStockDoc: 'Transfer stock document',
        transferStockInDoc: 'Transfer stock in document',
        transferStockOutDoc: 'Transfer stock out document',
    },
    report: {
        dashboard: 'Dashboard',
        transaction: 'Transactions',
        currentbill: 'Current Bill',
        daily: 'By Date',
        txtdaily: 'Sales report by Date',
        dailyDes: 'Sales report by date detail',
        sellbyProduct: 'By Bill Detail',
        txtsellbyProduct: 'Sales report by Bill Detail',
        sku: 'By Product',
        txtsku: 'Sales report by Product',
        plu: 'By Product (PLU)',
        textPlu: 'Sales report by Product (PLU)',
        paymentreport: 'Payment Report',
        txtpaymentreport: 'Payment Report',
        timesheet: 'Timesheet',
        txtTimesheet: 'Timesheet report',
        unsell: 'Non Selling Products',
        txtUnsell: 'Non Selling Products',
        byCategory: 'By Category',
        txtbyCategory: 'Sales report by Category',
        byCashier: 'By Cashier',
        txtbyCashier: 'Sales report by Cashiers',
        salesTax: 'Sales Tax report',
        txtSalesTax: 'Sales Tax report',
        voidbill: 'Void Bill',
        txtvoidbill: 'Void bill report',
        closedSale: 'Closed Sale',
        txtCloseSale: 'Drawer report ',
        salesByOrder: 'Sales summary',
        productByOrder: 'By Product ',
        unsalesByOrder: 'Void Bill ',
        txtDeliveryRoport: 'Payment by delivery report',
        txtCustomReport: 'Payment by custom report',
        txtSalesByOrder: 'Sales product by Order Device',
        txtDailyOrder: 'Sales by Order Device report',
        txtViodOrder: 'Void bill by Order Device',
        nonadjust: 'Non adjust stock product',
        txtnonadjust: 'Non adjust stock product report',
        stockInReport: 'Stock in by Product report',
        stockOutReport: 'Stock out by Product report',
        stockIn: 'Stock in by Product',
        stockOut: 'Stock out by Product',
        exportProduct: 'Export Product',
        txtExportProduct: 'Export product report',
        cashDrawer: 'Drawer',
        txtCashDrawerr: 'Cash management report',
        cashRevenue: 'Cash Revenue',
    },
    drawer: {
        cashDrawer: 'Drawer',
        txtCashDrawerr: 'Cash management report',
        cashRevenue: 'Summary of income-expenses',
        txtcashRevenue: 'Summary of income-expenses report',
    },
    member: {
        txtmember: 'Member',
        register: 'Shop register (LINE OA)',
        loyaltyPoint: 'Loyalty Point',
        storeCredit: 'Store Credit',
        deal: 'Deal',
        giftVoucher: 'Gift Voucher',
        referral: 'Invite',
        crm: 'Silom CRM',
    },
    unableStockDescription:
        'Unable to edit product due to stock update after document creation.',
    noItem: 'No item',
    enterProductName: 'Enter product name',
    ByCategory: 'By Category',
    ByCategoryDetail: 'By Category detail',
    maximum: 'Maximum',
}

export default messages
